import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import ImageModal from './ImageModal';
import Checkbox from './Checkbox';
import LoadingIndicator from './LoadingIndicator';

function VehicleCard({ 
  vehicle, 
  isEditMode, 
  onToggleEdit, 
  handleSave, 
  handleDelete, 
  handleVisibilityChange,
  isLoading, 
  loadingText 
}) {
  const [isModalOpen, setModalOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(vehicle.visibility || false);

  // State for input fields in edit mode
  const [adText, setAdText] = useState(vehicle.advertisementText || '');
  const [year, setYear] = useState(vehicle.year || '');
  const [make, setMake] = useState(vehicle.make || '');
  const [model, setModel] = useState(vehicle.model || '');
  const [price, setPrice] = useState(vehicle.price || '');
  const [color, setColor] = useState(vehicle.color || '');
  const [interior, setInterior] = useState(vehicle.interior || '');
  const [drive, setDrive] = useState(vehicle.drive || '');
  const [transmission, setTransmission] = useState(vehicle.transmission || '');
  const [vin, setVin] = useState(vehicle.vin || '');
  const [engine, setEngine] = useState(vehicle.engine || '');
  const [mileage, setMileage] = useState(vehicle.mileage || '');

  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  // Handle visibility change
  const onVisibilityChange = (newVisibility) => {
    setIsVisible(newVisibility);
    handleVisibilityChange(vehicle.id, newVisibility); // Pass to parent handler
  };

  // Handle change for number fields (year and mileage)
  const handleNumberInputChange = (e, setState) => {
    const value = e.target.value.replace(/[^0-9]/g, ''); // Allow only digits
    setState(value);
  };

  // Handle change for price field allowing decimal up to two places
  const handlePriceInputChange = (e) => {
    const value = e.target.value.replace(/[^0-9.]/g, ''); // Allow digits and dot
    if (/^\d*\.?\d{0,2}$/.test(value)) { // Allow only up to two decimal places
      setPrice(value);
    }
  };

  // Sort images by orderNumber
  const sortedImages = [...vehicle.images].sort((a, b) => a.orderNumber - b.orderNumber);

  // Find the main image with orderNumber of 1
  const mainImage = sortedImages.find(image => image.orderNumber === 1) || sortedImages[0];

  // Open modal at the selected image index
  const openModal = (index) => {
    setCurrentImageIndex(index);
    setModalOpen(true);
  };

  // Close modal
  const closeModal = () => setModalOpen(false);

  // Navigate to the next image
  const nextImage = () => {
    if (currentImageIndex < sortedImages.length - 1) {
      setCurrentImageIndex(currentImageIndex + 1);
    }
  };


  // Navigate to the previous image
  const prevImage = () => {
    if (currentImageIndex > 0) {
      setCurrentImageIndex(currentImageIndex - 1);
    }
  };


  // Function to handle the edit toggle and save if exiting edit mode
  const handleToggleEdit = () => {
    if (isEditMode) {
      handleSave(vehicle.id, {
        adText,
        year,
        make,
        model,
        price,
        color,
        interior,
        drive,
        transmission,
        vin,
        engine,
        mileage,
      });
    }
    onToggleEdit();
  };

  return (
    <div className="vehicle-card">
      <div className="vehicle-ad-row">
        {isEditMode ? (
          <label className="input-label">
            Ad Header:
            <input
              type="text"
              value={adText}
              onChange={(e) => setAdText(e.target.value)}
              className="vehicle-input"
            />
          </label>
        ) : (
          adText && <div>{adText}</div>
        )}
      </div>

      <div className="vehicle-image-container">
        <img
          src={mainImage.largeThumbUrl} // Main image with orderNumber 1
          alt={`${vehicle.year} ${vehicle.make} ${vehicle.model}`}
          className="vehicle-image"
          onClick={() => openModal(0)} // Open modal on click
        />
      </div>

      <div className={`vehicle-title ${isEditMode ? 'edit-mode-title' : ''}`}>
        {isEditMode ? (
          <div className="vehicle-title-inputs">
            <label className="input-label">
              Year:
              <input
                type="text"
                value={year}
                onChange={(e) => handleNumberInputChange(e, setYear)}
                className="vehicle-input"
              />
            </label>
            <label className="input-label">
              Make:
              <input
                type="text"
                value={make}
                onChange={(e) => setMake(e.target.value)}
                className="vehicle-input"
              />
            </label>
            <label className="input-label">
              Model:
              <input
                type="text"
                value={model}
                onChange={(e) => setModel(e.target.value)}
                className="vehicle-input"
              />
            </label>
            <label className="input-label price-label">
              Price:
              <input
                type="text"
                value={price}
                onChange={handlePriceInputChange}
                className="vehicle-input"
              />
            </label>
          </div>
        ) : (
          <div>{`${year && year + ' '}${make && make + ' '}${model}`}</div>
        )}
      </div>

      {!isEditMode && (
        <div className="vehicle-price">
          {price && <div>{`$${Number(price).toLocaleString()}`}</div>}
        </div>
      )}

      <div className="vehicle-details">
        {isEditMode ? (
          <>
            <label className="input-label">
              Color:
              <input type="text" value={color} onChange={(e) => setColor(e.target.value)} className="vehicle-input" />
            </label>
            <label className="input-label">
              Interior:
              <input type="text" value={interior} onChange={(e) => setInterior(e.target.value)} className="vehicle-input" />
            </label>
            <label className="input-label">
              Drive:
              <input type="text" value={drive} onChange={(e) => setDrive(e.target.value)} className="vehicle-input" />
            </label>
            <label className="input-label">
              Trans:
              <input type="text" value={transmission} onChange={(e) => setTransmission(e.target.value)} className="vehicle-input" />
            </label>
            <label className="input-label">
              VIN:
              <input type="text" value={vin} onChange={(e) => setVin(e.target.value)} className="vehicle-input" />
            </label>
            <label className="input-label">
              Engine:
              <input type="text" value={engine} onChange={(e) => setEngine(e.target.value)} className="vehicle-input" />
            </label>
            <label className="input-label">
              Mileage:
              <input type="text" value={mileage} onChange={(e) => handleNumberInputChange(e, setMileage)} className="vehicle-input" />
            </label>
          </>
        ) : (
          <>
            {color && <p><strong>Color:</strong> {color}</p>}
            {interior && <p><strong>Interior:</strong> {interior}</p>}
            {drive && <p><strong>Drive:</strong> {drive}</p>}
            {transmission && <p><strong>Trans:</strong> {transmission}</p>}
            {vin && <p><strong>VIN:</strong> {vin}</p>}
            {engine && <p><strong>Engine:</strong> {engine}</p>}
            {mileage && <p><strong>Mileage:</strong> {Number(mileage).toLocaleString()}</p>}
          </>
        )}
      </div>

      {isLoggedIn && (
        <div className="vehicle-admin-options-container">
          <div className="vehicle-admin-buttons-left">

            {/* Only show the edit button if not in edit mode */}
            {!isEditMode && (
              <button className="vehicle-edit-button" onClick={handleToggleEdit}>
                <img src="/images/edit.png" alt="Edit" style={{ width: '35px', height: '35px' }} />
              </button>
            )}

            {/* Only show the save button in edit mode */}
            {isEditMode && (
              <button className="vehicle-save-button" onClick={() => handleSave(vehicle.id, {
                adText,
                year,
                make,
                model,
                price,
                color,
                interior,
                drive,
                transmission,
                vin,
                engine,
                mileage,
              })}>
                <img src="/images/save.png" alt="Save" style={{ height: '35px' }} />
              </button>
            )}

            {/* Checkbox component */}
            <Checkbox
              checked={isVisible}
              onChange={onVisibilityChange}
              label="Visible"
              marginBottom="0"
              fontSize="1.2em"
            />

            {/* Loading indicator */}
            <LoadingIndicator 
              isLoading={isLoading} 
              text={loadingText} 
              alignment="left" 
              activitySize={15} 
            />
          </div>
          <button className="vehicle-delete-button" onClick={() => handleDelete(vehicle.id)}>
            <img src="/images/delete-icon.png" alt="Delete" style={{ width: '35px', height: '35px' }} />
          </button>
        </div>
      )}

      <div className="vehicle-image-icons">
        {sortedImages.map((image, index) => (
          <div key={image.id} className="image-icon" onClick={() => openModal(index)}>
            <img src={image.smallThumbUrl} alt={`Thumbnail ${index + 1}`} />
          </div>
        ))}
      </div>

      {isModalOpen && (
        <ImageModal
          images={sortedImages}
          currentIndex={currentImageIndex}
          onClose={closeModal}
          onNext={nextImage}
          onPrevious={prevImage}
        />
      )}
    </div>
  );
}

export default VehicleCard;



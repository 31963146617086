import React from 'react';
import './LoadingIndicator.css';

const LoadingIndicator = ({ 
  isLoading, 
  text, 
  activitySize = 35, // default size for spinner
  containerSize, // default to undefined, for full width
  alignment = 'left', // default alignment
  fontSize = '1.2em' // default font size, equivalent to "large"
}) => {
  if (!isLoading) return null;

  const dynamicContainerStyle = {
    justifyContent: 
      alignment === 'center' ? 'center' : 
      alignment === 'right' ? 'flex-end' : 'flex-start',
    ...(containerSize ? { width: containerSize, height: containerSize } : {}),
  };

  return (
    <div className="loading-indicator-container" style={dynamicContainerStyle}>
      <div 
        className="loading-spinner" 
        style={{ width: activitySize, height: activitySize }}
      />
      {text && <span className="loading-text" style={{ fontSize }}>{text}</span>}
    </div>
  );
};

export default LoadingIndicator;

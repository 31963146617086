// src/pages/HomePage.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';

import Header from '../components/Header';
import VehicleCard from '../components/VehicleCard';

import { getAuth } from 'firebase/auth';
import { apiBaseUrl } from '../apiConfig';

function HomePage() {
  const [vehicles, setVehicles] = useState([]);
  const [editModeVehicleId, setEditModeVehicleId] = useState(null); // Track which vehicle is in edit mode
  const [loadingVehicleId, setLoadingVehicleId] = useState(null); // Track which vehicle is loading
  const [loadingText, setLoadingText] = useState(''); // Text for the loading indicator

  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn); // Check if user is logged in


  useEffect(() => {
    const fetchVehicles = async () => {
      try {
        console.log("apiBaseUrl", apiBaseUrl);
        const response = await axios.get(`${apiBaseUrl}/listings`);
        console.log(response.data);
        setVehicles(response.data);
      } catch (error) {
        console.error("Error loading vehicles:", error);
      }
    };
    fetchVehicles();
  }, []);

  const handleSave = async (id, updatedData) => {
    setLoadingVehicleId(id);
    setLoadingText('Saving...');
    try {
      // Retrieve the Firebase auth token
      const auth = getAuth();
      const currentUser = auth.currentUser;
      if (!currentUser) {
        alert("You need to be logged in to save changes.");
        setLoadingVehicleId(null);
        setLoadingText('');
        return;
      }
      const idToken = await currentUser.getIdToken();
  
      const response = await axios.put(`${apiBaseUrl}/listing/${id}`, updatedData, {
        headers: {
          Authorization: `Bearer ${idToken}`, // Pass the token in the Authorization header
        },
      });
  
      if (response.status === 200) {
        // Update the vehicles list with the saved data
        setVehicles(vehicles.map(vehicle => (vehicle.id === id ? { ...vehicle, ...updatedData } : vehicle)));
        alert("Vehicle updated successfully");
  
        // Exit edit mode after successful save
        setEditModeVehicleId(null);
      } else {
        alert("Failed to save vehicle");
      }
    } catch (error) {
      console.error("Error saving vehicle:", error);
      alert("Error saving vehicle");
    } finally {
      setLoadingVehicleId(null);
      setLoadingText('');
    }
  };  



  const handleVisibilityChange = async (id, newVisibility) => {
    setLoadingVehicleId(id);
    setLoadingText('Updating visibility...');
    try {
      // Retrieve the Firebase auth token
      const auth = getAuth();
      const currentUser = auth.currentUser;
      if (!currentUser) {
        alert("You need to be logged in to change visibility.");
        setLoadingVehicleId(null);
        setLoadingText('');
        return;
      }
      const idToken = await currentUser.getIdToken();

      const response = await axios.patch(`${apiBaseUrl}/listing/${id}/visibility`, { visibility: newVisibility }, {
        headers: {
          Authorization: `Bearer ${idToken}`, // Pass the token in the Authorization header
        },
      });

      if (response.status === 200) {
        setVehicles(vehicles.map(vehicle => (vehicle.id === id ? { ...vehicle, visibility: newVisibility } : vehicle)));
        alert("Visibility updated successfully");
      } else {
        alert("Failed to update visibility");
      }
    } catch (error) {
      console.error("Error updating visibility:", error);
      alert("Error updating visibility");
    } finally {
      setLoadingVehicleId(null);
      setLoadingText('');
    }
  };

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this vehicle?");
    if (confirmDelete) {
      setLoadingVehicleId(id);
      setLoadingText('Deleting...');
      try {
        // Retrieve the Firebase auth token
        const auth = getAuth();
        const currentUser = auth.currentUser;
        if (!currentUser) {
          alert("You need to be logged in to delete this vehicle.");
          setLoadingVehicleId(null);
          setLoadingText('');
          return;
        }
        const idToken = await currentUser.getIdToken();

        const response = await axios.delete(`${apiBaseUrl}/listing/${id}`, {
          headers: {
            Authorization: `Bearer ${idToken}`, // Pass the token in the Authorization header
          },
        });

        if (response.status === 200) {
          setVehicles(vehicles.filter(vehicle => vehicle.id !== id));
          alert("Vehicle deleted successfully");
        } else {
          alert("Failed to delete vehicle");
        }
      } catch (error) {
        console.error("Error deleting vehicle:", error);
        alert("Error deleting vehicle");
      } finally {
        setLoadingVehicleId(null);
        setLoadingText('');
      }
    }
  };

  const toggleEditMode = (id) => {
    setEditModeVehicleId(prevId => (prevId === id ? null : id)); // Toggle edit mode for the specific vehicle
  };

  // Filter vehicles based on visibility and login status
  const filteredVehicles = isLoggedIn ? vehicles : vehicles.filter(vehicle => vehicle.visibility);

  return (
    <div className="full-width-container">
      <Header />
      <section className="content-container">
        <div className="vehicle-grid">
          {filteredVehicles.map(vehicle => (
            <VehicleCard
              key={vehicle.id}
              vehicle={vehicle}
              isEditMode={editModeVehicleId === vehicle.id}
              onToggleEdit={() => toggleEditMode(vehicle.id)}
              handleSave={handleSave}
              handleDelete={handleDelete}
              handleVisibilityChange={handleVisibilityChange}
              isLoading={loadingVehicleId === vehicle.id} // Pass isLoading based on vehicle ID
              loadingText={loadingText} // Pass the loading text
            />
          ))}
        </div>
      </section>
    </div>
  );
}

export default HomePage;